.toggle-lang {
    align-self: flex-start;
    background: $border-color;
    border-radius: $border-radius;
    margin-left: rem(4);
    padding: 2px;

    &:first-child {
        margin-left: 0;
    }

    .btn {
        border: 1px solid $border-color;
        border-radius: $border-radius !important;
        color: $gray;
        font-weight: $font-book;
        line-height: rem(24);
        
        img {
            display: inline-block;
            margin-top: -3px;
            opacity: .6;
            transition: opacity 0.15s ease-in-out;
        }

        &.active {
            background: $white;
            box-shadow: 2px 2px 6px rgba($black, .05);
            color: $black;
            img {
                opacity: 1;
            }
        }

        &:hover {
            color: $black;
            img {
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
        .btn {
            min-height: rem(42);
            padding-right: .45rem;
            padding-left: .45rem;
            img {
                margin-top: 0;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-left: rem(8);
        
        .btn {
            min-width: rem(75);
        }
    }
}