.nav-tabs {
    .nav-link {
        border-color: $border-color;
        color: $gray;
        font-size: 12px;
        padding: rem(14) rem(24);
        text-transform: uppercase;
        &:hover {
            color: $primary;
        }
        &.active {
            color: $gray-dark;
            border-bottom-color: transparent;
            font-weight: $font-bold;
        }
        &:not(.active) {
            background: #f8f8f8;
        }
    }

    .codeblock__tabs & {
        border-bottom: none;
        .nav-link {
            padding-top: rem(7);
            padding-bottom: rem(7);
            transition: none !important;
            &.active {
                background: $codeblock-bg;
                border-color: $gray-dark;
                border-bottom-color: transparent;
                color: $white;
            }
        }
    }
}