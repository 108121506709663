span.image-tooltip {
    // text-decoration: underline;
    // text-decoration-color: lighten($gray-dark,10%);
    // text-decoration-style: dashed;
    // text-underline-offset: 3px;
    border-bottom: 2px dotted $gray;
    padding: rem(2) 0;
}

.tooltip-inner {
    // background: $white;
    box-shadow: 0 5px 20px -4px rgba($black,.25);
    max-width: 65vw !important;
    @include media-breakpoint-down(lg) {
        max-width: 80vw !important;
    }
    @include media-breakpoint-down(md) {
        max-width: calc(100vw - 20px) !important;
    }
}

.tooltip img {
    // background: $white;
    display: block;
    height: auto;
   // max-height: calc(100vh - 150px);
    max-width: 100%;
    // width: auto;
}