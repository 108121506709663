.documentation {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 180px);

    &__navigation {
        background: $gray-light;
        height: rem(82);
        padding: rem(20) rem(24);
        position: sticky;
        top: 0;
        z-index: 5;
        
        
        @include media-breakpoint-down(md) {
            background: $white;
            height: auto;
            padding: rem(10) rem(24);
            position: relative;
            transition: $default-animation;
            &.is-hidden,
            &.is-sticky {
                position: sticky;
                top: 0;
                width: 100%;
                z-index: 15;
            }
            &.is-hidden {
                transform: translateY(-100%);
            }
            &.is-sticky {
                transform: translateY(0);
            }
        }
    }

    &__filters {
        flex-basis: 300px;
        width: 300px;
        @include media-breakpoint-down(md) {
            flex-basis: auto;
            width: auto;
        }
    }

    &__navbar {
        margin-left: rem(44);
        .navbar-toggle {
            border-color: $border-color !important;
            min-height: rem(40);
            text-align: left;
            width: 100%;
            &::after {
                background: url(../img/dropdown-arrow.svg) no-repeat;
                border: none;
                content: '';
                height: 6px;
                position: absolute;
                right: 0.75rem;
                top: rem(18);
                width: 0.625rem;
            }

            &:focus {
                box-shadow: none !important;
            }
        }
        .navbar {
            padding: 0;
        }

        .nav-item:not(:first-child) {
            margin-left: rem(56);
        }

        .nav-link {
            color: $gray-dark;
            font-size: .75em;
            padding: 1rem .5rem 1.75rem;
            position: relative;
            text-transform: uppercase;

            &.active {
                color: $black;
                font-weight: $font-bold;

                &::after {
                    background: $primary;
                    border-radius: 3px;
                    bottom: 0;
                    content: '';
                    height: 6px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }
        }
        @include media-breakpoint-down(md) {
            flex: 1;
            margin-left: rem(4);
            position: relative;
            .navbar-collapse {
                background: $white;
                border: 1px solid $border-color;
                border-radius: $border-radius;
                box-shadow: 0px 5px 20px -4px rgba($black, 0.08);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
            }
            .nav {
                display: block;
                padding: rem(4) 0;
            }
            .nav-item:not(:first-child) {
                margin-left: 0;
            }
            .nav-link {
                padding: .5rem 1rem;
    
                &.active {
                    color: $black;
                    font-weight: $font-bold;
    
                    &::after {
                        bottom: unset;
                        content: '';
                        height: 100%;
                        left: 0;
                        top: 0;
                        width: 6px;
                    }
                }
            }
        }
    }

    &__search {
        display: none;
        position: absolute;
        right: rem(140);
        top: rem(25);
        .is-sticky-desktop & {
            display: block;
        }

        @include media-breakpoint-down(md) {
            display: none !important;
        }
    }

    &__wrapper {
        background: $white;
        display: flex;
        flex: 1 0 auto;
        flex-flow: row nowrap;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &__sidebar {
        flex-basis: 300px;
        //flex: 1 0 auto;
        width: 300px;
        // overflow-y: auto;
        
        .navbar {
            padding: 0;
            position: sticky;
            top: rem(82);
            .toc {
                max-height: calc(100vh - 82px);
                overflow-y: auto;
                padding: rem(32) 0 rem(64);
            }
        }

        .btn {
            display: none;
        }

        @include media-breakpoint-down(md) {
            background: $white;
            border-bottom: 1px solid $border-color;
            overflow: visible;
            padding: 0 rem(24) rem(10);
            transition: transform $default-timing $default-easing;
            width: 100%;
            z-index: 9;

            &.is-hidden,
            &.is-sticky {
                position: sticky;
                top: rem(62);
                width: 100%;
                z-index: 10;
            }
            &.is-hidden {
                transform: translateY(calc(-100% - 62px));
            }
            &.is-sticky {
                box-shadow: 0 10px 34px -8px rgba($black, .17);
                transform: translateY(0);
            }

            .navbar {
                padding: 0;
                position: relative;
                top: 0;
                .toc {
                    max-height: calc(100vh - 150px);
                    padding-top: 0;
                    padding-bottom: 0;
                    ul {
                        margin-bottom: 0;
                    }
                }
            }

            .is-sticky .navbar .toc {
                max-height: calc(100vh - 62px);
            }

            .btn {
                background: $gray-light;
                color: $primary;
                display: block;
                font-size: .75em;
                font-weight: $font-bold;
                overflow: hidden;
                padding: rem(8) rem(32) rem(8) rem(24);
                position: relative;
                text-align: left;
                text-overflow: ellipsis;
                text-transform: none;
                white-space: nowrap;
                width: 100%;

                &::after {
                    background: url(../img/dropdown-arrow.svg) no-repeat;
                    border: none;
                    content: '';
                    height: 6px;
                    position: absolute;
                    right: rem(12);
                    top: rem(15);
                    width: rem(10);
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .navbar-collapse {
                background: $white;
                border: 1px solid $border-color;
                border-radius: $border-radius;
                box-shadow: 0 10px 34px -8px rgba($black, .17);
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 100;
            }
        }

        @include media-breakpoint-up(md) {
            .toc {
                width: 100%;
            }
        }
    }

    &__content {
        box-shadow: 0px 0px 12px 0px rgba($black, .08);
        
        flex-grow: 1;
        padding: rem(32) rem(32) rem(32) rem(64);
        width: calc(100vw - 300px);
        @include media-breakpoint-down(md) {
            
            box-shadow: none;
            padding: rem(24);
            width: 100%;
        }
    }

    &__main {
        display: flex;
        flex-flow: row nowrap;
        @include media-breakpoint-down(md) {
            flex-flow: column wrap;
        }
    }

    &__body {
        max-width: calc(100% - 230px);
        padding-top: rem(32);
        padding-right: rem(84);
        position: relative;
        z-index: 1;

        &--full {
            margin-left: rem(32);
            max-width: none;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: rem(64)
        }

        @include media-breakpoint-up(xl) {
            &--full {
                margin-left: rem(64);
                padding-bottom: rem(96);
            }
        }
        
        @include media-breakpoint-down(md) {
            max-width: 100%;
            order: 2;
            padding-top: 0;
            padding-right: 0;
            &--full {
                margin-left: 0;
            }
        }
    }

    &__bottom {
        border-top: 1px solid $border-color;
        margin-top: rem(32);
        padding-top: rem(32);

        @include media-breakpoint-down(sm) {
            .col-6:first-child {
                padding-right: rem(5);
            }
            .col-6:last-child {
                padding-left: rem(5);
            }
        }
    }

    &__aside {
        align-self: flex-start;
        border-left: 1px solid $border-color;
        padding-left: rem(32);
        position: sticky;
        top: rem(116); // TEMP Test sticky
        @include media-breakpoint-down(md) {
            // border-top: 1px solid $border-color;
            // border-bottom: 1px solid $border-color;
            border-left: none;
            margin-bottom: 0;
            order: 1;
            padding: 0;
            position: relative;
            top: unset;
            width: 100%;
        }
    }



    &__heading {
        border-bottom: 1px solid $border-color;
        font-size: 2.25em;
        font-weight: $font-bold;
        line-height: 1.388888888888889;
        max-width: calc(100% - 310px);
        padding-bottom: rem(12);

        &.is-borderless {
            border-bottom: none;
            padding-bottom: 0;
        }

        @include media-breakpoint-down(md) {
            border-bottom: none;
            font-size: 1.875em;
            line-height: 1.21875;
            // margin-bottom: rem(24);
            max-width: none;
            padding-bottom: rem(24);
        }
    }
}