%clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

%responsive-image {
    display: block;
    height: auto;
    max-width: 100%;
}

%cover-image {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
}