.frontpage {

    &__hero {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 0;
        padding-top: rem(116);

        &-text {
            margin-top: rem(30);
            padding-left: rem(36);
            position: relative;

            &::before {
                background: $primary;
                border-radius: 1.5px;
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                width: 3px;
            }
        }

        .badge-light {
            background: $white;
            border: 1px solid $border-color;
        }

        @include media-breakpoint-down(md) {
            background-position: 0% 0%;
            padding-top: rem(64);
            padding-bottom: rem(64);
            text-align: center;

            &-text {
                padding-left: 0;
                &::before {
                    display: none;
                }
                .lead {
                    font-size: .9375em;
                }
                p.text-muted {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    a {
                        line-height: 24px;
                        margin-left: 10px;
                    }
                }
            }

            .btn {
                margin: rem(30) auto 0;
            }
        }
    }

    &__heading {
        background: url(../img/double-chevrons.svg) no-repeat 0 6px;
        margin-bottom: rem(30);
        padding-left: rem(26);
        text-transform: uppercase;
        a {
            color: $body-color;
            &:hover,
            &:focus {
                color: $pink;
                text-decoration: none;
            }
        }
    }

    &__features {
        border-bottom: 1px solid $border-color;
        .row > div:first-child {
            border-right: 1px solid $border-color;
        }

        @include media-breakpoint-down(sm) {
            .container-lg {
                padding-right: 0;
                padding-left: 0;
            }
            .row > div:first-child {
                border-right: none;
                border-bottom: 1px solid $border-color;
            }
        }
    }

    &__workflows,
    &__samples {
        //background: $white;
        padding: rem(60) 0;
        position: relative;
    }
    
    &__workflows {
        z-index: 5;
    }

    &__samples {
        padding-top: rem(15);
        z-index: 10;
        
        .tab-content {
            background: $white;
            border: 1px solid $border-color;
            border-top: none;
            border-top-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            margin-bottom: 2rem;
            padding: rem(30);
            p {
                font-size: 0.9375em;
            }
            .img-fluid {
                border-radius: 0;
                display: block;
                margin: rem(24) auto 0;
                padding: .5rem;
            }
        }
        .highlight {
            background: $codeblock-bg;
            border-radius: $border-radius;
            padding: rem(30);
        }
        pre, figure {
            color: $white;
            margin-bottom: 0;
        }

        @include media-breakpoint-down(md) {
            .nav-tabs {
                align-items: stretch;
                .nav-item {
                    flex: 1;
                }
                .nav-link {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 10px;
                    height: 100%;
                    padding: rem(9) rem(10);
                    text-align: center;
                }
            }
            .tab-content {
                border-top-right-radius: 0;
                padding: rem(16);
                p, pre {
                    font-size: .75em;
                }
            }
        }
    }

    &__noteworthy {
        box-shadow: inset 0 -2px 22px rgba($black,.05);
        padding: rem(60) 0;
        .row {
            margin-top: 2rem;
        }
    }

    &__blog {
        > div {
            margin-bottom: 1.5rem;
        }
        .card {
            height: 100%;
        }
    }

    &__all {
        margin-top: 2.75rem;
        @include media-breakpoint-down(md) {
            padding: 0 rem(30);
        }
    }

    &__events {
        padding-top: rem(60);
        padding-bottom: rem(60);
    }

}

.workflows__pattern {
    background: url(../img/frontpage/frontpage-dots-background.svg) no-repeat 50% 0;
    background-size: cover;
    height: 120%;
    position: absolute;
    top:  15%;
    width: 100%;
    z-index: -1;
}

.workflow {
    &:not(:last-child) {
        margin-bottom: 3rem;
        .text-right {
            border-bottom: 1px solid $border-color;
            padding-bottom: 30px;
        }
    }
}

.event {
    border-bottom: 1px solid $border-color;
    padding: rem(32) 0;
    &:first-child {
        padding-top: 0;
    }

    &__image {
        flex: 1;
        max-width: 330px;
    }

    &__info {
        flex: 1;
        padding-bottom: rem(20);
        padding-left: rem(24);
        position: relative;
        .arrow-link {
            bottom: 0;
            position: absolute;
            right: 0;
        }
    }

    &__name {
        line-height: rem(30);
        //margin-top: rem(5);
        margin-bottom: 0;
    }

    &__meta {
        color: $gray-dark;
        display: block;
        font-size: 12px;
        margin: rem(4) 0 rem(8);
    }

    &__description {
        color: $gray;
        font-size: 15px;
    }
    @include media-breakpoint-down(sm) {
        &__image {
            max-width: 100%;
        }
        &__info {
            padding-left: 0;
        }
        &__name {
            margin-top: rem(20);
        }

        &:last-child {
            border-bottom: none;
        }
    }
}