.page-header {
    padding: rem(32) 0;

    &__date  {
        color: $gray;
        font-size: .75em;
        text-transform: uppercase;
    }

    h1 {
        font-size: 2.25em;
        max-width: 730px;
    }

    &__image {
        margin: rem(32) rem(-24) 0;
    }
    
    @include media-breakpoint-down(sm) {
        &__image {
            margin: rem(32) -1rem 0;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: rem(64) rem(32) rem(32);
        &__image {
            margin: rem(32) rem(-64) 0;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: rem(72) rem(64) rem(32);
        /* &__image {
            margin: rem(32) rem(-64) 0;
        } */
    }
}