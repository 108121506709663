// Colours
$primary: $pink;

$gray-100: #F8F8F8;

// Body
$body-color: #303030;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1300px
) !default;

// Typography
$font-family-sans-serif: "Gotham SSm A", "Gotham SSm B", sans-serif;
//$headings-font-weight: $font-black;
$h5-font-size: 1.125em;
$h6-font-size: .75em;

$display4-size: 2.875em;
$display4-weight: $font-book;

$lead-font-size: 1.125em;
$lead-font-weight: $font-book;

// Borders
$border-color: #E5E5E5;
$border-radius: 3px;

// Buttons
$btn-font-weight: $font-bold;

// Badges
$badge-font-weight: $font-book;
$badge-padding-y: .4em;
$badge-padding-x: .6em;

// Cards
$card-spacer-x: 1.5rem;
$card-spacer-y: 1.5rem;

// Dropdown
$dropdown-border-color: $border-color;
$dropdown-link-hover-color: $pink;
$dropdown-link-hover-bg: rgba($pink, .06);
// $dropdown-link-active-bg: rgba($pink, .8);

// Modal
$modal-backdrop-opacity: .8;

// Navbar
$navbar-brand-font-size: 1.125rem;

// Thumbnails
$thumbnail-border-radius: 0;
$figure-caption-font-size: .75em;

// Code
$code-font-size: 1em;

// Tooltips
$tooltip-font-size: .625em;
$tooltip-bg: $white;
$tooltip-border-radius: 3px;
$tooltip-arrow-color: $white;
$tooltip-opacity: 1;
$tooltip-padding-y: 15px; 
$tooltip-padding-x: 15px;

// Tables
$table-cell-padding: 1rem;