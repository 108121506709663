.arrow-link {
    color: $gray-600;
    display: inline-block;
    font-size: 12px;
    padding-right: 31px;
    position: relative;
    text-transform: uppercase;

    > img {
        display: inline-block;
        margin-right: 15px;
    }

    &__icon {
        display: inline-block;
        left: calc(100% - 31px);
        margin-left: 6px;
        overflow: hidden;
        position: absolute;
        text-align: right;
        top: 50%;
        transform: translateY(-50%);
        transition: all .25s ease-out;
        width: 25px;
        
    }

    svg {
        float: right;
        path {
            fill: $gray-600;
        }
    }

    &:hover,
    &:focus {
        color: $pink;
        text-decoration: none;
        .arrow-link__icon {
            width: 37px;
        }
        svg path {
            fill: $pink;
        }
    }

    &--no-caps {
        font-size: 15px;
        text-transform: none;
    }
    /* @include media-breakpoint-down(lg) {
        > img {
            height: 46px;
            margin-right: 10px;
            width: 46px;
        }
    } */
    &--play {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        > img {
            max-width: 66px;
        }
        .arrow-link__text {
            flex: 1;
        }
    }
}

.external-link {
    font-size: 11px;
    font-weight: $font-medium;
    line-height: 18px;
    text-transform: uppercase;

    &::after {
        background: url(../img/external-link-icon.svg) no-repeat 50% 50%;
        content: '';
        display: inline-block;
        height: 8px;
        margin-left: 5px;
        width: 8px;
    }
}