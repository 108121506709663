.btn {
    font-size: 12px;
    text-transform: uppercase;

    &-lg {
        padding: 15px 30px;
    }
}

.btn-primary {
    @include gradient-x($red,$pink);
    box-shadow: 0 18px 34px -14px rgba($red,.3);
    //border-color: transparent !important;

    &:hover,
    &:focus {
        background: linear-gradient(-90deg, rgb(245, 59, 121) 0%, rgb(251, 61, 69) 100%);
        border-color: rgb(245, 59, 121);
        box-shadow: 0 20px 44px -9px rgba($red,.4);
    }
}

.btn-light {
    background: $white;
    border: 1px solid $border-color;
    box-shadow: 0 5px 20px -4px rgba($black, .08);

    &:hover,
    &:focus {
        background: $white;
        border-color: darken($border-color, 10%);
        box-shadow: 0px 10px 34px -8px rgba(1, 1, 1, 0.15);
    }
}

.btn-search {
    border: 1px solid $border-color;
    border-radius: 16px;
    font-weight: normal;
    text-align: left;
    text-transform: none;
    width: 150px;

    &:hover,
    &:focus {
        border-color: $primary;
    }
}