$white: #fff;
$black: #010101;

$red: #EC1C24;
$pink: #D91C5C;

$gray: #808080;
$gray-dark: #3b3b3b;
$gray-light: #f8f8f8;

$codeblock-bg: #1d1f21;
$codeblock-title-bg: $black;