.documentation__body {
    table:not(.rouge-table):not(.d2h-diff-table) {
        opacity: 0;

        &.table {
            opacity: 1;
        }
    }
}

.table-responsive {
    margin-bottom: rem(32);
    .table {
        margin-bottom: 0;
    }
}
.table {
    thead {
        th,
        td {
            background: $gray-light;
            border-bottom-width: 1px;
            vertical-align: middle;
        }
    }

    th, td {
        font-size: 1em;
        position: relative;
        // line-height: 1.16666;
        p:last-child {
            margin-bottom: 0;
        }

       /*  br {
            content: ' ';
            display: block;
            height: 1.5em;
        } */
    }
    td[rowspan] {
        vertical-align: middle;
    }

    // Custom CSS Classes
    th.empty {
        background: none !important;
        border: none !important;
    }
    td.th {
        background: $gray-light;
        font-weight: bold;
    }
    td.v-top {
        vertical-align: top;
    }
    td.v-middle {
        vertical-align: middle;
    }
    td.v-bottom {
        vertical-align: bottom;
    }

    // Superscript
    td sup {
        position: absolute;
        top: unset;
    }

    // Paragraphs
    td p:not(:last-child),
    div.p:not(:last-child) {
        margin-bottom: 1em;
    }


    // Text Replacement Icons
    .table-icon {
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        height: 16px;
        margin: 2px 0;
        width: 16px;
    }
    .table-checkmark {
        background-image: url(../img/table-checkmark.svg);
    }
    .table-cross {
        background-image: url(../img/table-cross.svg);
    }
    .table-dash {
        background-image: url(../img/table-dash.svg);
    }
}

.table-borderless {
    thead th,
    td.th {
        border: 1px solid $border-color;
    }
    tbody {
        td {
            border-bottom: 1px solid $border-color;
        }
        td:last-child {
            border-right: 1px solid $border-color;
        }
    }
}
.table.vertical-align {
    td {
        vertical-align: middle;
    }
}

.table-legend {
    border: 1px solid $border-color;
    border-top: none;
    font-size: .75em;
    margin-top: rem(-32);
    margin-bottom: rem(32);
    padding: 1rem;
    p {
        margin-bottom: .25rem;
        &:last-child {
            margin-bottom: none;
        }
    }

    ol {
        margin-top: .25rem;
        margin-bottom: 0;
        > li {
            padding-left: 0;
            margin-bottom: .25rem;
            &::before {
                background: none;
                border-radius: unset;
                color: inherit;
                content: counter(custom-counter) '. ';
                display: inline-block;
                font-size: inherit;
                font-weight: inherit;
                height: auto;
                line-height: 1;
                margin-right: rem(4);
                position: relative;
                top: unset;
                width: auto;
            }
        }
    }
}