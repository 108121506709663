.card {
    overflow: hidden;
    &-body {
        position: relative;
        &::before {
            background: $pink;
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: $default-animation;
            width: 3px;
        }
        p:last-child,
        h3:last-child {
            margin-bottom: 0;
        }
    }

    &:hover .card-body::before {
        opacity: 1;
    }

    &--noteworthy {
        box-shadow: 0px 0px 22px 0px rgba($black, 0.05);
        transition: $default-animation;
        .badge {
            color: $gray;
            left: 15px;
            padding: 4px 15px;
            position: absolute;
            text-transform: uppercase;
            top: 15px;
            z-index: 5;
        }
        .card-body {
            padding: rem(32);
            .h5 {
                line-height: 1.4;
                margin-bottom: .25rem;
            }
            p {
                color: $gray;
                font-size: 0.9375em;
                line-height: 1.5;
                margin-bottom: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }
            .arrow-link {
                margin-top: 2rem;
            }

           /*  @include media-breakpoint-down(lg) {
                padding: rem(24);
            }  */
        }

        figure {
            margin-bottom: 0;
        }

        & + & {
            margin-top: rem(20);
        }

        &:hover {
            box-shadow: 0 10px 34px -8px rgba($black,0.17);
        }

        @include media-breakpoint-down(md) {
            &:only-child {
                margin-bottom: 1.5rem;
            }
        }
    }

    &--blog {
        figure {
            height: 0;
            margin-bottom: 0;
            overflow: hidden;
            padding-bottom: 54.25%;
            position: relative;
            img {
                height: 100%;
                object-fit: cover;
                object-position: 50% 50%;
                position: absolute;
                width: 100%;
            }
        }
    }
}