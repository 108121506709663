.modal-video {
    .modal-content {
        background: none;
        border: none;
    }
    .modal-header {
        border-bottom: none;
        padding: 0 0 10px;
    }
    .responsive-embed {
        background: $black;
    }
}

.modal-search {
    max-width: 1440px;
    padding-left: rem(32);
    padding-right: rem(32);
    .modal-content {
        background: none;
        border: none;
        border-radius: 0;
        flex-direction: row;
    }

    @include media-breakpoint-down(md) {
        margin: 0;
        padding: 0;
    }
}

.modal .close {
    background: $gray-200;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    margin: 0 0 0 auto;
    opacity: 1;
    position: relative;
    width: 40px;
    will-change: opacity;
    z-index: 5;
    
    &::before,
    &::after {
        background: $gray-dark;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        margin-left: -10px;
        position: absolute;
        width: 20px;
    }

    &::before {
        transform: rotate(-45deg);
    }

    &::after {
        transform: rotate(45deg);
    }

    @include media-breakpoint-down(md) {
        background: $white;
        border: 1px solid $border-color;
        height: 36px;
        width: 36px;
        &::before {
            transform: rotate(-45deg) scale(.65);
        }
        &::after {
            transform: rotate(45deg) scale(.65);
        }
    }
}