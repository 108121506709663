.page-wrapper {
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.container, 
div[class^="container-"] {
    padding-right: rem(32);
    padding-left: rem(32);

    @include media-breakpoint-down(md) {
        padding-right: rem(24);
        padding-left: rem(24);
    }
    @include media-breakpoint-down(sm) {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}