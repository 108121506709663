// Responsive Embed Video
.responsive-embed {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.222222%;
    position: relative;
}

.responsive-embed embed,
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed video {
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 1080px;
    position: absolute;
}

// Clickable
.clickable,
.card--video {
    cursor: pointer;
    &:hover,
    &:focus {
        .arrow-link {
            color: $pink;
            .arrow-link__icon {
                width: 37px;
            }
            svg path {
                fill: $pink;
            }
        }
    }
}
  