.share {
    margin-top: rem(32);
    &__title {
        border-right: 1px solid $border-color;
        text-transform: uppercase;
        padding-right: rem(32);
    }
    li:not(.share__title) {
        padding-left: rem(20) !important;
    }
    a {
        border-bottom: none !important;
        display: inline-block;
        padding: 0 rem(12) !important;
        &:hover svg path {
            fill: $pink;
        }
    }
}