.error-page {
    min-height: calc(100vh - 240px);
    padding: rem(64) 0;

    h1 {
        font-weight: $font-bold;
    }
    p.lead {
        font-size: 1.5em;
    }
    p {
        margin-top: 1.5rem;
    }
    p:not(.lead) {
        margin-bottom: rem(32);
    }

    .callout {
        max-width: 380px;
        p {
            margin-top: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        min-height: unset;
        padding: rem(32) 0;
        .img-fluid {
            display: block;
            margin-right: auto;
            margin-left: auto;
            max-width: 250px;
        }
        .callout {
            max-width: none;
        }
    }
}