.community {
    padding: rem(96) 0 rem(74);

    &__heading {
        padding-left: rem(36);
        position: relative;

        &::before {
            background: $primary;
            border-radius: 1.5px;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            width: 3px;
        }

        p {
            font-size: 18px;
        }
    }

    .callout--sm .callout__icon {
        min-width: 42px;
        text-align: center;
        width: 42px;
    }

    @include media-breakpoint-down(md) {
        padding: rem(44) 0 rem(84);
        &__heading {
            margin-bottom: rem(48);
            padding-bottom: rem(24);
            padding-left: 0;
            text-align: center;

            &::before {
                bottom: 0;
                height: 3px;
                left: 50%;
                transform: translateX(-50%);
                width: 84px;
            }
        }
    }
}