.site-header {
    box-shadow: 0 0 22px rgba(0,0,0,.05);
    padding: 0 4px 0 16px;
    position: sticky;
    top: 0;
    z-index: 999;

    .layout--documentation & {
        position: relative;
    }

    &__backdrop {
        background: rgba($gray-dark,.3);
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 500;
        &.is-visible {
            display: block;
        }
    }

    .navbar-brand {
        font-weight: $font-bold;
        line-height: rem(44);
        img {
            margin-right: rem(10);
        }
    }
    .navbar-nav {
        > .nav-item {
            padding-right: .5rem;
            padding-left: .5rem;
        }
        .nav-link {
            color: $gray-dark;
            font-size: 12px;
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            text-transform: uppercase;

            &::after {
                background: url(../img/dropdown-arrow.svg) no-repeat;
                border: none;
                height: 6px;
                vertical-align: .015em;
                width: rem(10);
            }

            &:hover,
            &:focus {
                color: $primary;
            }

            &[aria-expanded="true"] {
                &::after {
                    transform: rotate(-180deg);
                }
            }

            &.active {
                color: $gray-dark;
                font-weight: $font-bold;
            }
        }
        .dropdown-menu {
            box-shadow: 0 10px 34px -8px rgba($black,.17);
            left: 2rem;
            padding: 0;
            > li:first-child {
                padding-top: rem(12);
            }
            > li:last-child {
                padding-bottom: rem(12);
            }
        }
        .dropdown-item {
            font-size: 12px;
            line-height: 2em;
            padding: .75rem 1.5rem;
            text-transform: uppercase;
            .icon {
                display: inline-block;
                margin: -3px 6px -3px -6px;
            }
            &.active {
                background: none;
                color: $gray-dark;
                font-weight: bold;
            }
        }
    }

    &__search {
        padding-right: rem(32);
        padding-left: rem(32);
    }

    &__social {
        svg path {
            fill: #333;
        }
    }

    @include media-breakpoint-down(lg) {
        .navbar-brand {
            font-size: 15px;
            img {
                height: auto;
                width: 37px;
            }
        }
        .navbar-nav {
            .nav-link {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        &__search {
            padding-right: rem(16);
            padding-left: rem(16);
        }
    }

    @include media-breakpoint-down(md) {
        padding: 0;

        .navbar-toggler {
            border: none;
            border-radius: 0;
            height: 36px;
            margin-right: 5px;
            outline: none !important;
            padding: 0;
            position: relative;
            width: 36px;
        }

        
        .navbar-collapse {
            background: $white;
            border: 1px solid $border-color;
            left: 0;
            position: absolute;
            top: 100%;
            width: 100%;

            &.show {
                box-shadow: 0px 5px 20px -4px rgba(0, 0, 0, 0.08);
            }
            > div:last-child {
                padding: rem(52) rem(24) rem(24);
                .nav-item {
                    border-bottom: none;
                }
                .nav-link svg {
                    height: rem(24);
                    width: rem(24);
                }
            }
        }
        .navbar-nav {
            > .nav-item {
                border-bottom: 1px solid $border-color;
                padding-right: 0;
                padding-left: 0;
            }
            .dropdown-toggle::after {
                float: right;
                margin-top: 6px;
            }
            .dropdown-menu {
                border: none;
                border-radius: none;
                box-shadow: none;
                margin-top: 0;
                li:last-child {
                    padding-bottom: .75rem;
                }
            }
            .dropdown-item {
                padding-left: 2.75rem;
                position: relative;
            }
        }
        .nav-link {
            padding-top: .75rem;
            padding-bottom: .75rem;
            position: relative;
            &.active::before {
                background-image: linear-gradient(225deg, $pink 0%, $red 100%);
                border-radius: 3px;
                content: '';
                height: 30px;
                left: 0;
                margin-top: -15px;
                position: absolute;
                top: 50%;
                width: 6px;
            }
        }
    }
}

.hamburger {
    background: $gray;
    border-radius: 3px;
    display: inline-block;
    left: 8px;
    margin-top: -1px;
    height: 2px;
    position: absolute;
    top: 50%;
    width: 11px;

    &::before,
    &::after {
        background: $gray;
        border-radius: 3px;
        content: '';
        display: inline-block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 16px;
    }

    &::before {
        top: -6px;
    }

    &::after {
        top: 6px;
    }

    [aria-expanded="true"] & {
        background: none;

        &::before,
        &::after {
            margin-left: 5px;
        }

        &::before {
            transform: rotate(45deg) translateY(8.5px);
        }

        &::after {
            transform: rotate(-45deg) translateY(-8.5px);
        }
    }
}

.mobile-search {
    background: $white;
    border: 1px solid $border-color;
    border-radius: 50%;
    height: 35px;
    padding: 0;
    width: 35px;
    svg {
        display: inline-block;
        height: 16px;
        position: relative;
        top: -1px;
        width: 16px;
    }
}