.callout {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    &.card {
        box-shadow: 0 5px 20px -4px rgba($black,.1);
        margin-bottom: 1.875rem;
        transform: translateZ(0);
        transition: box-shadow $default-timing $default-easing;
        &::before {
            background: $pink;
            content: '';
            height: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: $default-animation;
            width: 3px;
        }
    
        &:hover {
            box-shadow: 0px 10px 34px -8px rgba($black,.15);
            &::before {
                opacity: 1;
            }
        }

        @include media-breakpoint-down(md) {
            height: auto !important;
            margin-bottom: 1.25rem;
        }
    }

    &__icon {
        margin-right: 1.375rem;
    }

    &__img {
        flex: 1;
        margin-right: rem(56);
        min-width: 300px;
        width: 300px;
    }

    &__text {
        flex: 1;
        p {
            margin-bottom: 0 !important;
        }
    }

    &__arrow {
        display: inline-block;
        left: calc(100% - 31px);
        margin-left: 6px;
        overflow: hidden;
        position: absolute;
        text-align: right;
        top: rem(36);
        transform: translateY(-50%);
        transition: all .25s ease-out;
        width: 25px;
    }

    &__version {
        margin-left: rem(64);
        span {
            background: $white;
            box-shadow: 0px 5px 20px -4px rgba(0, 0, 0, 0.08);
            border-radius: 50%;
            color: $primary;
            display: block;
            height: 138px;
            line-height: 138px;
            margin-bottom: 0;
            text-align: center;
            width: 138px;
        }

        @include media-breakpoint-down(sm) {
            order: 1;
        }
    }

    .arrow-link {
        margin-top: rem(8);
    }

    a.stretched-link {
        position: unset !important;
    }
    
    a[target="_blank"]::after {
        display: none !important;
    }

    &:hover .callout__arrow {
        width: 37px;
        svg path {
            fill: $pink;
        }
    }

    svg {
        float: right;
        path {
            fill: $gray-600;
        }
    }

    &--sm {
        .callout__text {
            
            p {
                font-size: 12px !important;
                min-height: rem(36);
            }
        }
    }

    &--md {
        .callout__text {
            .h5 {
                font-weight: $font-bold;
                text-transform: uppercase;
            }
        }
    }

    &--lg {
        padding: rem(48) rem(40);

        .callout__icon {
            margin-right: rem(40);
        }

        .callout__text {
            .h5 {
                color: $primary;
            }
            p {
                font-size: 15px;
                margin-bottom: rem(32);
            }
        }

        @include media-breakpoint-down(md) {
            padding: rem(48) rem(24);
            .callout__icon {
                margin-right: rem(30);
            }
        }
    }
    
    &--xl {
        .card-body {
            align-items: flex-end;
            padding-bottom: 0;
            padding-left: rem(8);
        }

        .callout__text {
            padding-top: .5rem;
            padding-bottom: rem(32);
            p {
                margin-bottom: 1rem;
            }
        }

        h3 {
            display: flex;
            flex-flow: row;
            align-items: baseline;
            img {
                display: inline-block;
                height: auto;
                margin-right: rem(8);
                max-width: 22px;
            }
            span {
                display: inline-block;
                line-height: rem(25);
            }
        }
        @include media-breakpoint-down(sm) {
            .card-body {
                padding: 0;
            }
            .callout__img {
                margin: 0 auto;
                padding-top: 1rem;
                text-align: center;
                width: 330px;
            }
            .callout__text {
                border-top: 1px solid $border-color;
                padding: 1.5rem;
                p {
                    margin-bottom: 1.25rem;
                }
            }
        }
    }

    &--banner {
        .card-body {
            align-items: center;
            padding-bottom: rem(24);
            padding-right: rem(64);
            padding-left: rem(64);
        }
        .callout__text {
            min-height: 138px;
            .h5 {
                font-weight: $font-bold;
                text-transform: uppercase;
            }
            .arrow-link {
                margin-top: rem(24);
                margin-right: rem(24);
            }
        }

        @include media-breakpoint-down(sm) {
            .card-body {
                flex-flow: column nowrap;
                padding: 0;
            }
            .callout__text {
                border-top: none;
                order: 2;
                width: 100%;
                .arrow-link {
                    margin-top: 1rem;
                    margin-right: 0;
                }
                .arrow-link + .arrow-link {
                    margin-top: .5rem;
                }
            }
            .callout__version {
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                margin-left: 0;
                padding-top: rem(24);
                width: 100%;
            }
        }
    }

    &--prev,
    &--next {
        .callout__text {
            display: inline-block;
            height: 100%;
            padding-bottom: rem(24);
            position: relative;
            .h6 {
                color: $gray;
                font-size: .625em;
            }
            p {
                font-size: 0.9375em !important;
                font-weight: bold;
                margin-bottom: 0;
                min-height: 0;
            }
            a[target="_blank"]::after {
                display: none  !important;
            }
        }

        @include media-breakpoint-down(lg) {
            .card-body {
                padding: 1rem;
            }
            .callout__text {
                max-width: none;
            }
        }
    }

    &--prev {
        &::before,
        .card-body.card-body::before {
            left: auto !important;
            right: 0;
        }
        .card-body {
            text-align: right;
        }
        .callout__text {
            padding-left: 35px;
        }
        .callout__arrow {
            left: auto;
            right: 0;
            transform: rotate(180deg);
            top: calc(100% - 16px);
        }

        @include media-breakpoint-down(xl) {
            .callout__text {
                padding-left: 0
            }
        }
    }

    &--next {
        .callout__text {
            padding-right: 35px;
        }
        .callout__arrow {
            left: 0;
            margin-left: 0;
            top: calc(100% - 12px);
        }

        @include media-breakpoint-down(xl) {
            .callout__text {
                padding-right: 0;
            }
        }
    }
    
    &--related {
        height: auto !important;
        margin: rem(32) 0;
        .callout__text {
            .h6 {
                color: $gray;
                font-size: .625em;
            }
            p {
                font-size: 0.9375em !important;
                font-weight: bold;
                margin-bottom: 0;
                min-height: 0;
            }
            a[target="_blank"]::after {
                display: none;
            }
        }
        @include media-breakpoint-down(sm) {
            .card-body {
                padding: 1rem;
            }
        }
    }
}

.row .callout.card {
    height: calc(100% - 1.875rem);
}