.site-footer {
    position: relative;
    z-index: 5;
    .h6 {
        display: block;
        font-weight: $font-bold;
        margin-bottom: rem(12);
    }

    .nav-link {
        color: $gray;
        font-size: 12px;
        &:hover,
        &:focus {
            color: $primary;
            text-decoration: underline;
        }
    }

    &__brand {
        > div {
            height: 100%;
        }
    }

    &__nav {
        border-top: 1px solid $border-color;
        padding: rem(120) 0;
        .nav-link {
            padding-left: 0;
        }
    }

    &__legal {
        padding: rem(35) 0;
        .nav-item {
            text-transform: uppercase;
        }
    }

    &__copyright {
        color: $gray;
        font-size: .75em;
        margin-bottom: 0;
        white-space: nowrap;
    }

    @include media-breakpoint-down(md) {
        &__legal {
            text-align: center;

            .container-xl > .row {
                > div:nth-child(1) {
                    margin-top: rem(26);
                    order: 3
                }
                > div:nth-child(2) {
                    order: 1
                }
                > div:nth-child(3) {
                    margin-top: rem(26);
                    order: 2
                }
            }
        }

        &__social {
            .nav {
                align-items: center;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .h6 {
            min-height: 2.4em;
        }
        &__nav {
            padding: rem(32) 0;
        }
        &__brand {
            margin-bottom: rem(48);
        }
    }
}