.versions-dropdown {
    .dropdown-menu {
        max-height: 65vh;
        min-width: 240px;
        overflow: scroll;
    }
    ul {
        margin-bottom: .5em;
        padding-left: 0;
    }
    .dropdown-item  {
        padding: 0;
        > a,
        > label {
            padding: .5em rem(18) .5em rem(50);
            
        }
        > a.external-link {
            padding-left: rem(18);
        }
    }
    .dropdown-item.active, 
    .dropdown-item:active {
        background-color: $gray-100;
        color: $gray-dark;
    }
    .btn {
        box-shadow: 0 5px 20px -4px rgba($black,.08) !important;
    }
    .btn-outline-secondary.dropdown-toggle {
        background: $white !important;
        border-color: $border-color !important;
        font-weight: $font-book;
        min-height: rem(42);
        &:hover,
        &:focus,
        &:active {
            color: $primary !important;
        }
    }
    &.show > .btn-outline-secondary.dropdown-toggle {
        color: $primary !important;
    }
    .dropdown-toggle {
        &::after {
            background: url(../img/dropdown-arrow.svg) no-repeat;
            background-size: contain;
            border: none;
            height: rem(5);
            vertical-align: .05em;
            width: rem(8);
        }
        &[aria-expanded="true"] {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }

    small {
        font-size: .75em;
        padding: 0 rem(18);
    }
}