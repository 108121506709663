body {
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	-moz-osx-font-smoothing: grayscale;
}

//---------------------------------------------------
// Gotham Smartscreen Font
// Requirement: http://cloud.typography.com
//
// Weights Avalaible
// Book: 400
// Medium: 500
// Bold: 700
// Black: 800
//---------------------------------------------------
@mixin gotham($size: 1em, $weight: 400, $transform: none) {
	font-family: 'Gotham SSm A', 'Gotham SSm B', Helvetica, sans-serif;
	font-style: normal;
	font-size: $size;
	font-weight: $weight;
    text-transform: $transform;
}

// Font Weights
$font-book: 400;
$font-medium: 500;
$font-bold: 700;
$font-black: 800;