// Custom Radios & Checkboxes
input[type="radio"],
input[type="checkbox"] {
    @include sr-only();
}

.form-check-label,
a.radio-link {
    cursor: pointer;
    color: $gray;
    display: block;
    font-size: .75em;
    margin-bottom: 0;
    position: relative;

    img {
        display: inline-block;
        margin-right: rem(4);
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        border: 1px solid $border-color;
        height: 1rem;
        width: 1rem;
        z-index: 0;
    }

    &::after {
        transform: scale(0);
        transition: transform .25s;
        z-index: 1;
    }

    &:hover,
    &:focus {
        color: $pink;
        text-decoration: none;
    }
}

input[type="radio"] + label,
a.radio-link {
    padding: rem(4) 0 rem(4) rem(24);
    
    text-transform: uppercase;

    &::before,
    &::after {
        border-radius: 50%;
    }

    &::before {
        left: rem(18);
        top: rem(6);
    }

    &::after {
        background: $primary;
        height: 10px;
        left: rem(21);
        width: 10px;
        top: rem(9);
    }

    &:hover::before,
    &:focus::before {
        border-color: $pink;
    }
}

input[type="radio"]:checked + label::after,
input[type="checkbox"]:checked + label::after,
a.radio-link.checked::after {
    transform: scale(1);
}

input[type="checkbox"] + label {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: rem(32);
    &::before {
        border-radius: $border-radius;
        left: rem(10);
        top: rem(12);
    }
    &::after {
        background: url(../img/pink-checkmark.svg) no-repeat;
        background-size: contain;
        height: rem(9);
        left: rem(12);
        top: 1rem;
        width: rem(12);
    }
}