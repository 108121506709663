.feedback {
    &_trigger {
        background: $white;
        border: 1px solid $border-color;
        border-right: none;
        bottom: 60px !important;

        &:hover,
        &:focus {
            background: $white !important;
            color: $pink;
        }

        &_text {
            font-size: 12px !important;
            letter-spacing: 0 !important;
            right: -8px !important;
        }
    }

    &_content {
        background: $white;
        border: 1px solid $border-color;
        border-top-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        bottom: 10px !important;
        box-shadow: 0 10px 34px -8px rgba(1, 1, 1, 0.17);
        padding: 1rem 1.5rem;

        

        &.email_present {
            height: 430px;
        }

        ul {
            padding-left: 0;
            li {
                margin-right: 0;
                margin-bottom: .75rem;
            }
        }

        label {
            color: $gray;
            font-size: .75em;
            margin-bottom: 2px;
            text-transform: uppercase;
        }
        input,
        textarea {
            border: 1px solid $gray;
            color: $gray-dark;
        }
    }

    &_title {
        font-size: .9375em;
        font-weight: bold;
        padding-top: 0 !important;
        span {
            display: block;
            margin-left: 0 !important;
        }
    }

    &_name,
    &_email,
    &_message {
        padding: .25rem .5rem;
        width: 100%;
    }

}

.fm_clean {
    background: $white;
    button {
        
        @include gradient-x($red,$pink);
        box-shadow: 0 18px 34px -14px rgba($red,.3);
        color: $white;
        font-family: $font-family-sans-serif;
        font-size: .75em;
        padding: 10px 20px;
        text-shadow: none;
        text-transform: uppercase;

        &:hover,
        &:focus {
            box-shadow: 0 20px 44px -9px rgba($red,.4);
        }
    }
}

.feedback-delayed-dlg {
    background-color: $white !important;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    box-shadow: 0 10px 34px -8px rgba(1, 1, 1, 0.17);
    z-index: 9999;
}

.feedback-dlg-close {
    color: $white;
    font-size: 0;
    height: 20px;
    right: 5px;
    top: 5px;
    width: 20px;
    &::before,
    &::after {
        background: $gray-dark;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        margin-top: 8px;
        margin-left: -10px;
        position: absolute;
        width: 20px;
    }

    &::before {
        transform: rotate(-45deg);
    }

    &::after {
        transform: rotate(45deg);
    }
}

.feedback-fail-message {
    color: $red;
}
.feedback-success-fail-message-inner {
    font-size: .9375em;
}