.top-banner {
    background-image: linear-gradient(225deg, $pink 0%, $red 100%);
    color: $white;
    padding: 1rem 0;
    p {
        margin-bottom: 0;
    }
    
    a {
        color: inherit;
        font-weight: bold;
    }

    &:hover {
        background-image: linear-gradient(225deg, darken($pink, 7.5%) 0%, darken($red, 7.5%) 100%);
        a {
            text-decoration: underline;
        }
    }
}