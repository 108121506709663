.toc {
    &__divider {
        border-top: 1px solid $border-color;
        height: 0;
        margin: rem(8) 0 rem(8) rem(26);
    }

    &__icon {
        left: rem(26);
        position: absolute;
        top: 0;
    }

    li:not(.toc__divider) {
        padding-top: rem(5);
        padding-bottom: rem(5);
    }

    a {
        color: $gray-dark;
        display: block;
        font-size: .75em;
        padding-top: rem(5);
        padding-right: rem(32);
        padding-bottom: rem(5);
        position: relative;
        &:hover,
        &:focus {
            text-decoration: none;
        }
        &.dropdown-toggle {
            &::after {
                background: url(../img/dropdown-arrow.svg) no-repeat;
                border: none;
                height: 6px;
                position: absolute;
                right: rem(32);
                top: rem(12);
                vertical-align: .015em;
                width: rem(10);
            }

            &[aria-expanded="true"] {
                &::after {
                    transform: rotate(-180deg);
                }
            }
        }

        &.active {
            background: $gray-100;
            color: $primary;
            font-weight: $font-medium;
        }
    }

    > ul {
        > li {
            
            > a {
                padding-left: rem(70);
                &.dropdown-toggle {
                    color: $gray;
                    font-weight: $font-medium;
                    text-transform: uppercase;

                    &:hover,
                    &:focus {
                        color: $black;
                    }
                }
            }
            > ul {
                > li {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    > a {
                        padding-left: rem(70);
                    }
                    > ul {
                        padding-left: rem(66);
                        > li {
                            padding-top: 0 !important;
                            padding-bottom: 0 !important;
                            > a {
                                padding-left: rem(30);
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding-top: rem(4);
        &__divider {
            margin: rem(5) rem(24);
        }
        > ul > li > ul > li > ul {
            padding-left: 0;
            > li > a {
                padding-left: rem(96);
            }
        }
    }
}

.page-toc {

    &:not(:first-child) {
        margin-top: rem(32);
    }

    .h6 {
        color: $gray;
        svg {
            position: relative;
            top: -1px;
        }

        &:only-child {
            display: none;
        }
    }

    

    .nav {
        display: block;
    }
    ul:last-child {
        margin-bottom: 0;
    }
    li {
        font-size: .75em;
        margin-top: .5rem;
        a.nav-link {
            color: $gray-dark;
            padding: 0;
            position: relative;
            &:hover,
            &:focus {
                color: $primary;
            }
            &.active::before {
                background: $pink;
                border-radius: 1.5px;
                content: '';
                height: 13px;
                left: -34px;
                position: absolute;
                top: 0;
                width: 3px;
            }

            // External links
            &[target="_blank"] {
                &::after {
                    background: url(../img/external-link-icon-gray.svg) no-repeat 50% 50%;
                    white-space: nowrap;
                    content: '\00a0';
                    display: inline-block;
                    height: 8px;
                    margin-left: 4px;
                    position: relative;
                    top: 5px;
                    width: 8px;
                }
                &:hover,
                &:focus {
                    &::after {
                        background-image:  url(../img/external-link-icon.svg);
                    }
                }
            }
        }
        ul {
            list-style: none;
            padding-left: 1em;
            li {
                font-size: 1em;
                a.nav-link.active::before {
                    left: -46px;
                }
            }
        }
    }
    
    @include media-breakpoint-down(md) {
        &:first-child .h6 {
            border-top: 1px solid $border-color;
            padding-top: rem(24);
        }

        &:last-child ul {
            border-bottom: 1px solid $border-color;
            margin-bottom: rem(32);
            padding-bottom: rem(24);
        }
    }
}