html {
    scroll-behavior: smooth;
}
body {
    position: relative;
}
.documentation__body {
    // Headings
    > h1, > h2, > h3, > h4, > h5, > h6 {
        font-weight: $font-bold;
        margin-top: rem(32);
        margin-bottom: rem(6);
        position: relative;

        &[id]{
            pointer-events: none;
            span {
                pointer-events: auto;
            }
            &::before {
                display: block;
                height: 7rem;
                margin-top: -7rem;
                content: "";
                pointer-events: none;
                z-index: -1;
            }
        }
        

        .anchorjs-link {
            background: url(../img/icon-anchor-link.svg) no-repeat;
            background-size: 16px 16px;
            border-bottom: none;
            //bottom: rem(6);
            color: $gray !important;
            height: 16px;
            margin-left: -24px !important;
            padding-right: rem(8) !important;
            text-decoration: none !important;
            top: 7.3rem;
            width: 24px;
        }

        @include media-breakpoint-down(md) {
            .anchorjs-link {
                margin-left: -18px !important;
                width: 18px;
            }
        }
    }
    h2:not(.h1):not(.h3):not(.h4):not(.h5):not(.h6) {
        font-size: 1.5em;
        line-height: 1.5;
        margin-bottom: 1rem;
        .anchorjs-link {
            top: 7.5rem;
        }
    }
    h3:not(.h1):not(.h2):not(.h4):not(.h5):not(.h6) {
        font-size: 1.125em;
        line-height: 1.6;
    }
    h4:not(.h1):not(.h2):not(.h3):not(.h5):not(.h6) {
        font-size: 1em;
        line-height: 1.6;
    }
    h5:not(.h1):not(.h2):not(.h3):not(.h4):not(.h6) {
        color: $gray;
        font-size: .75em;
        text-transform: uppercase;
        line-height: 1.2;
    }
    h6:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5) {
        font-size: 0.625em;
        line-height: 1.5;
    }

    // Body font styling
    p, li, table:not(.d2h-diff-table) {
        font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
    p:not(.lead), li {
        font-size: 1em;
        line-height: 1.6;
        margin-bottom: 1rem;
    }
    p.lead {
        margin-bottom: rem(32);
    }

    // Lists
    li {
        margin-bottom: .5rem;
    }
    ul:not(.nav):not(.list-unstyled),
    ol {
        list-style: none;
        margin-top: 1rem;
        padding-left: 0;
        li {
            padding-left: rem(37);
            position: relative;
            p {
                font-size: 1em !important;
            }
        }
        ul,
        ol {
            margin-top: 0;
            li {
                font-size: 1em;
            }
        }
    }
    ul:not(.nav):not(.list-unstyled) {
        > li {
            &::before {
                background: #BABABA;
                border-radius: 50%;
                content: '';
                height: 6px;
                left: rem(17);
                position: absolute;
                top: rem(9);
                width: 6px;
            }

            > ul {
                padding: rem(8) 0;
                > li::before {
                    background: none;
                    border: 1px solid #BABABA;
                }
            }
        }
    }
    ol {
        counter-reset: custom-counter;
        > li {
            counter-increment: custom-counter;
            margin-bottom: 1rem;
            &::before {
                background: $gray;
                border-radius: 50%;
                color: $white;
                content: counter(custom-counter);
                display: block;
                font-family: $font-family-sans-serif;
                font-size: 0.8125em;
                font-weight: $font-bold;
                height: rem(22);
                left: 0;
                line-height: rem(22);
                position: absolute;
                text-align: center;
                top: 1px;
                width: rem(22);
            }
            > ol {
                list-style: lower-alpha;
                padding: rem(8) 0;
                > li {
                    margin-left: 2rem;
                    margin-bottom: .5rem;
                    padding-left: rem(12);
                    &::before {
                        content: unset;
                    }
                    > ol {
                        list-style: lower-roman;
                        padding: rem(8) 0;
                    }
                }
            }
        }
    }

    // Hyperlinks
    a:not(.arrow-link):not(.anchorjs-link):not(.nav-link) {
        border-bottom: 1px solid $gray;
        color: $body-color;
        padding: rem(2) 0;
        position: relative;
        text-decoration: none;
        transition: border .25s;

        &.has-code {
            padding-bottom: rem(5);
        }

        &:hover,
        &:focus {
            border-bottom-color: $primary;
            color: $primary;
        }

        // External links
        &[target="_blank"] {
            &::after {
                background: url(../img/external-link-icon-gray.svg) no-repeat 50% 50%;
                white-space: nowrap;
                content: '\00a0';
                display: inline-block;
                height: 8px;
                margin-left: 4px;
                position: relative;
                top: 8px;
                width: 8px;
            }
            &:hover,
            &:focus {
                &::after {
                    background-image:  url(../img/external-link-icon.svg);
                }
            }
        }

        // Inline Code
        code {
            line-height: 1.4;
        }
    }

    // Named Anchor Links
    a[name] {
        position: relative;
        pointer-events: none;

        &::before {
            content: "";
            display: block;
            height: 9rem;
            margin-top: -9rem;
            pointer-events: none;
            z-index: -1;
        }        
    }

    // Inline Code
    code {
        background: $gray-light;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        color: $gray-dark;
        display: inline-block;
        font-size: .9em;
        line-height: 1.5;
        padding: 0 rem(8);
    }

    // Code Blocks
    div.highlight,
    figure.highlight {
        background: $codeblock-bg;
        border-radius: $border-radius;
        margin: rem(32) 0;
        padding: 1rem;
        position: relative;
        pre {
            background: none;
            font-size: 100%;
        }
        code {
            background: none;
            border: none;
            border-radius: 0;
            color: unset;
            font-size: 1em;
            min-width: 100%;
            margin: rem(12) 0;
            padding: 0;
        }
    }
    pre, figure {
        color: $white;
        line-height: 1.6;
        margin-bottom: 0;
    }

    // Line numbers
    .rouge-table td.gl {
        padding-right: rem(8);
    }

    .codeblock {
        &__title {
            background: $codeblock-title-bg;
            border-top-right-radius: $border-radius;
            border-top-left-radius: $border-radius;
            color: $white;
            font-size: .75em;
            margin-top: rem(32);
            padding: rem(4) 1rem;
            text-transform: uppercase;

            & + .highlighter-rouge > div.highlight {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                margin-top: 0;
            }
        }
        &__btn {
            background: $gray;
            border: none;
            border-radius: 50%;
            display: inline-block;
            height: rem(31);
            position: absolute;
            right: rem(8);
            top: rem(8);
            width: rem(31);
            z-index: 5;

            .codeblock__copy {
                background: url(../img/icon-clipboard.svg) no-repeat;
                content: '';
                display: inline-block;
                height: rem(17);
                left: rem(7);
                pointer-events: none;
                position: absolute;
                top: rem(7);
                width: rem(17);
            }
        }
    }

    // Code Tabs
    .tab-pane {
        div.highlight,
        figure.highlight {
            border-top-left-radius: 0;
            margin-top: 0;
        }
    }

    // Diff2Html
    .d2h-diff-table {
        font-family: $font-family-monospace;
        font-size: 1em;
    }

    // Figures
    .figure {
        margin-top: rem(32);
        margin-bottom: rem(32);
    }
    img {
        @extend .img-fluid;
    }

    .img-inline {
        display: inline-block;
        line-height: 1;
        position: relative;
        top: -1px;
    }

    // Alerts
    .alert {
        background: $gray-light;
        border: none;
        border-radius: $border-radius;
        color: $gray-dark;
        margin: rem(32) 0;
        overflow: hidden;
        padding: rem(24) rem(32);

        &::before {
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 4px;
        }

        &-heading {
            color: $gray;
            margin-bottom: rem(12);
            padding-left: rem(30);
            position: relative;
            text-transform: uppercase;

            &::before {
                content: '';
                height: 25px;
                left: 0;
                position: absolute;
                top: -6px;
                width: 25px;
            }
        }

        p:last-child,
        ul:last-child,
        ol:last-child,
        div[class^="language"]:last-child div.highlight,
        figure.highlight:last-child {
            margin-bottom: 0;
        }

        &-note {
            &::before {
                background-color: $gray;
            }
            .alert-heading::before {
                background: url(../img/alert-note-icon.svg);
            }
        }

        &-warning {
            &::before {
                background-color: #FFBB4B;
            }
            .alert-heading::before {
                background: url(../img/alert-warning-icon.svg);
            }
        }

        &-danger {
            &::before {
                background-color: #C73583;
            }
            .alert-heading::before {
                background: url(../img/alert-danger-icon.svg);
            }
        }

        &-important {
            &::before {
                background-color: #0490fd;
            }
            .alert-heading::before {
                background: url(../img/alert-important-icon.svg);
            }
        }



        &-tip {
            &::before {
                background-color: #2EBCA7;
            }
            .alert-heading::before {
                background: url(../img/alert-tip-icon.svg);
            }
        }


    }

    // Horizontal rules
    hr {
        margin-top: rem(32);
        margin-bottom: rem(32);
    }

    // Blockquotes
    blockquote {
        margin: rem(78) 0 rem(32);
        position: relative;
        @extend %clearfix;

        &:before {
            content: "\201C ";
            font-family: $font-family-sans-serif;
            font-size: 140px;
            font-weight: $font-bold;
            left: -56px;
            line-height: 1;
            opacity: .1;
            position: absolute;
            top: -38px;
            z-index: 0;
        }

        p {
            color: $gray;
            font-size: 28px !important;
            max-width: none !important;
            position: relative;
            z-index: 5;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        footer {
            float: right;
            text-align: right;

            cite {
                font-size: 1.5em;
                font-style: normal;

                .h5 {
                    font-size: .5em;
                    font-weight: $font-bold;
                    text-transform: uppercase;
                }
            }

        }

        @include media-breakpoint-down(sm) {
            &:before {
                font-size: 100px;
                left: -18px;
                top: -26px;
            }
            p {
                font-size: 20px !important;
            }
        }
    }

    // Embeds
    .responsive-embed {
        margin: rem(32) 0;
    }

    // Grid
    .row {
        margin-top: 1rem;
    }

    .row + .row {
        margin-top: 0;
    }

    .row + hr {
        margin-top: rem(2);
    }

    // Callout
    .callout--xl.card {
        border: none;
        box-shadow: none;
    }

    // Full Page Article Specifics
    &--full {
        p, ul, ol, figure {
            max-width: 720px;
        }
        p, li, table:not(.d2h-diff-table) {
            font-family: $font-family-sans-serif;
        }
        mark {
            background-color: #FF0056;
            color: $white;
        }
    }
}
