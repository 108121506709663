.search {
    &__sidebar,
    &__body {
        flex: 1;
        margin-right: rem(16);
    }

    &__sidebar {
        max-width: 310px;
    }

    &__filters {
        background: $white;
        border-radius: $border-radius;
        position: sticky;
        top: rem(28);
    }

    &__body {
        background: $white;
        border-radius: $border-radius;
        max-width: 848px;
    }

    &__close {
        order: 3;
        button {
            position: sticky !important;
            top: rem(28);
        }

        @include media-breakpoint-up(xxl) {
            width: calc(100% - 1190px);
        }
    }


    &__header {
        .search__close {
            display: none;
        }
    }

    &__input {
        border-bottom: 1px solid $border-color;
        position: relative;

        .search__close {
            display: none;
        }

        input {
            appearance: none;
            -webkit-appearance: none;
            background: url(../img/icon-search.svg) no-repeat 40px 50%;
            background-size: 30px 30px;
            caret-color: $primary;
            border: none;
            display: block;
            font-size: 1.125em;
            font-weight: $font-medium;
            outline-width: 0;
            padding: rem(22) rem(40) rem(22) rem(82);
            width: 100%;
            &::placeholder,
            &::-webkit-input-placeholder {
                color: $gray;
                font-weight: $font-book;
            }
        }
        .ais-SearchBox-reset {
            background: $white;
            border: none;
            color: $primary;
            font-size: .75em;
            font-weight: $font-medium;
            position: absolute;
            right: rem(40);
            top: 50%;
            transform: translateY(-50%);
        }
        .ais-SearchBox-loadingIndicator {
            display: block;
            height: 24px;
            position: absolute;
            right: rem(60);
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
        }
    }

    &__empty,
    &__no-results {
        align-items: center;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        min-height: 535px;
        // padding-top: 120px;
        h3, p {
            color: $gray;
            text-align: center;
        }
        h3 {
            font-weight: $font-bold;
            margin-top: rem(24);
        }
        p {
            font-size: 1.125em;
            line-height: 1.666667;
            max-width: rem(566);
        }
    }
    &__results,
    &__no-results {
        display: none;
    }

    &__back {
        padding: rem(32) rem(40);
        a {
            font-size: .75em;
            color: $gray;
            text-transform: uppercase;
            svg {
                margin-left: rem(8);
                position: relative;
                top: -1px;
            }
            &:hover {
                color: $primary;
                text-decoration: none;
                svg path {
                    fill: $primary;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        transition: all .3s;
        &.js-filters {
            transform: translateX(310px);
        }
       
        /* &__sidebar,
        &__header,
        &__empty,
        &__results,
        &__no-results {
            transition: transform .3s linear;
            .js-filters & {
                transform: translateX(310px);
            }
        } */

        &__sidebar,
        &__body {
            margin-right: 0;
            min-height: 100vh;
        }

        &__sidebar {
            background: $white;
            box-shadow: 0 5px 20px -4px rgba($black,.08);
            height: 100%;
            position: sticky;
            margin-left: -310px;
            //margin-left: -310px;
            // right: 100%;
            top: 0;
            width: 310px;
            z-index: 10;
        }

        &__filters {
            border-radius: 0;
            position: relative;
            top: 0;
        }

        /* &__body {
            padding-top: 70px;
        } */

        &__close {
            display: none;
        }

        &__header {
            background: $gray-300;
            padding: rem(14) rem(64) rem(14) rem(46);
            position: sticky;
            top: 0;
            transition: transform .25s;
            width: 100%;
            z-index: 2;
            .search__close {
                display: block;
                position: absolute;
                right: rem(14);
                top: 1rem;
            }
            

            &.is-hidden {
                transform: translateY(-100%);
            }
            &.is-sticky {
                transform: translateY(0);
            }
        }
        &__input {
            border-bottom: none;
            input {
                background-color: $white;
                background-position: 10px 50%;
                background-size: 20px 20px;
                border-radius: 3px;
                font-size: .875em;
                line-height: 1.5;
                padding: rem(10) rem(41) rem(10) rem(38);
            }
            .ais-SearchBox-reset {
                right: 4px;
            }
        }
        &__empty,
        &__no-results {
            p {
                padding: 0 rem(20);
            }
        }
        &__back {
            padding: rem(32) calc(1.5rem + 11px);
        }
    }
}

.results {
    &__group:not(:last-child) {
        border-bottom: 1px solid $border-color;
    }
    &__indice {
        padding: rem(32);
        text-align: right;
        .h5 {
            color: $gray-dark;
            display: block;
            font-size: .9175em;
            font-weight: $font-book;
        }
    }

    &__count {
        color: $gray;
        display: block;
        font-size: .75em;
    }


    &__hits {
        border-left: 1px solid $border-color;
        padding: rem(32) rem(47) rem(32) rem(32);
    }

    &__more {
        // margin-right: rem(-32);
        text-align: right;
        a {
            cursor: pointer;
        }
        .more-button {
            font-size: .75em;
            color: $gray;
            text-transform: uppercase;
            svg {
                margin-left: rem(8);
                position: relative;
                top: -1px;
            }
            &:hover {
                color: $primary;
                text-decoration: none;
                svg path {
                    fill: $primary;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__indice {
            padding: rem(24) calc(1.5rem + 11px);
            text-align: left;
            .h5 {
                display: inline-block;
                margin-bottom: 0;
            }
        }
        &__count {
            display: inline-block;
            margin-left: rem(6);
        }
        &__hits {
            border-left: none;
            padding: 0 calc(1.5rem + 11px) rem(24);
        }
    }
}

.result {
    &:not(:first-child) {
        padding-top: rem(24);
    }
    &__title {
        font-size: .9375em;
        font-weight: $font-bold;
        margin-bottom: rem(4);
        a {
            color: $gray-dark;
            &:hover,
            &:focus {
                color: $pink;
            }
        }
    }
    &__external {
        &::after {
            background: url(../img/external-link-icon-gray.svg) no-repeat 50% 50%;
            white-space: nowrap;
            content: '\00a0';
            display: inline-block;
            height: 8px;
            //margin-left: 5px;
            position: relative;
            top: 7px;
            width: 8px;
        }
        &:hover,
        &:focus {
            &::after {
                background-image:  url(../img/external-link-icon.svg);
            }
        }
    }
    mark {
        background-color: rgba(#da1b5b, .15);
        padding: 0;
    }
   

    &__date {
        color: $gray;
        display: block;
        font-size: .625em;
        line-height: 1.5;

        p + & {
            margin-top: rem(8);
        }
    }

    p {
        color: $gray-dark;
        font-size: .75em;
        line-height: 1.6;
        margin-bottom: 0;
    }

    &__breadcrumbs {
        color: $gray-dark;
        font-size: .625em;
        line-height: 1.5;
        margin-top: rem(8);
        .list-inline-item:not(:first-child) {
            margin-left: .5rem;
        }
    }

    &__tags {
        font-size: .625em;
        > li {
            margin-top: rem(8);
        }
    }
}

#searchTop {
    display: block;
}


// Filters
.filters {
    &__toggle,
    &__backdrop {
        display: none;
    }
    &__facets {
        justify-content: space-between;
        padding: rem(15) rem(18);
        
        .versions-dropdown {
            .dropdown-menu {
                min-width: 274px;
            }
        }
        ul {
            padding-left: 0;
        }
        .btn {
            box-shadow: 0 5px 20px -4px rgba($black,.08) !important;
        }
        label.btn-outline-secondary {
            background: $white !important;
            border-color: $border-color !important;
            font-weight: $font-book;
            min-height: rem(42);
            &:hover,
            &:focus,
            &:active {
                color: $primary !important;
            }
        }
    }
    &__indices {
        border-top: 1px solid $border-color;
        display: none;
        .h6 {
            color: $gray;
            padding: rem(24) rem(18) rem(4);
        }
        .nav {
            padding-bottom: rem(18);
        }
        .nav-item:not(:last-child) {
            margin-bottom: rem(2);
        }
        .nav-link {
            color: $gray-dark;
            font-size: .9375em;
            line-height: 1;
            span {
                color: $gray;
                font-size: .8em;
                margin-left: rem(4);
                pointer-events: none;
            }

            &.active {
                background: $gray-100;
                font-weight: $font-bold;
                span {
                    color: $primary;
                    font-weight: $font-book;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &__backdrop {
            background: $black;
            //display: block;
            height: 100vh;
            left: 0;
            opacity: $modal-backdrop-opacity;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            .js-filters & {
                display: block;
            }
        }
        &__toggle {
            background: $white;
            border: none;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            box-shadow: 0 5px 20px -4px rgba($black,.08);
            display: inline-block;
            left: 0;
            line-height: 0;
            padding: rem(9) rem(10) rem(8);
            position: absolute;
            top: rem(14);
            z-index: 5;
            svg {
                pointer-events: none;
            }

            &:focus {
                outline: none;
            }

            &.is-active {
                svg path {
                    fill: $primary;
                }
            }
        }
    }
}