.gdpr {
    background: $gray-light;
    border-top: 1px solid $border-color;
    bottom: 0;
    box-shadow: 0 -10px 34px -8px rgba($black, .17);
    display: none;
    padding: rem(32) 0;
    position: fixed;
    width: 100%;
    z-index: 999;

    .row {
        margin-left: -10px;
        margin-right: -10px;
        > div[class^="col"] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    p {
        font-size: .9375em;
        line-height: 1.6;
        margin-bottom: 0;
        a {
            color: inherit;
            text-decoration: underline;
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        p {
            margin-bottom: rem(32);
        }
        &__buttons {
            margin-right: auto !important;
            margin-left: auto !important;
            max-width: 670px;
        }
    }

    @include media-breakpoint-only(xs) {
        &__buttons {
            margin-right: -10px !important;
            margin-left: -10px !important;
            > div:first-child {
                margin-bottom: rem(10);
            }
        }
    }
    /* @include media-breakpoint-only(xs) {
        
    } */
}