// ------------------------------------------
// Import Variables & Settings
// ------------------------------------------
@import 'settings/animation';
@import 'settings/colours';
@import 'settings/fonts';
@import 'settings/paths';

// ------------------------------------------
// Import Bootstrap + Override variables
// ------------------------------------------
@import 'settings/custom-bootstrap';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// ------------------------------------------
// Import Diff2HTML base CSS
// ------------------------------------------
@import 'vendors/diff2html.min.css';

// ------------------------------------------
// Import jQuery Feedback Me base CSS
// ------------------------------------------
@import 'vendors/jquery.feedback_me.css';

// ------------------------------------------
// Utilities
// ------------------------------------------
@import 'utilities/extends';
@import 'utilities/functions';
@import 'utilities/helpers';

// ------------------------------------------
// Import Layout Partials
// ------------------------------------------
@import 'layout/header';
@import 'layout/footer';
@import 'layout/page';
@import 'layout/documentation';

// ------------------------------------------
// Common Styles
// ------------------------------------------
@import 'common/typography';

// ------------------------------------------
// Modules
// ------------------------------------------
@import 'modules/badge';
@import 'modules/buttons';
@import 'modules/callout';
@import 'modules/card';
// @import 'modules/codediff';
@import 'modules/documentation';
@import 'modules/gdpr';
@import 'modules/feedback';
@import 'modules/highlight';
@import 'modules/image-tooltip';
@import 'modules/links';
@import 'modules/modal';
@import 'modules/page-header';
@import 'modules/radios';
@import 'modules/share';
@import 'modules/search';
@import 'modules/tables';
@import 'modules/tabs';
@import 'modules/tocs';
@import 'modules/toggle';
@import 'modules/top-banner';
@import 'modules/versions';

// ------------------------------------------
// Pages Specific Styles
// ------------------------------------------
@import 'pages/frontpage';
@import 'pages/community';
@import 'pages/404';